:root {
  --primary-color-light: #f8f9fa;
  --primary-color-dark: #333333;
  --secondary-color-light: #e0f7fa;
  --secondary-color-dark: #424242;
  --bot-background-color-light: #e8eaf6;
  --bot-background-color-dark: #1e1e1e;
  --border-color-light: #dee2e6;
  --border-color-dark: #424242;
  --text-color-light: black;
  --text-color-dark: white;
  --background-color-light: #ffffff;
  --background-color-dark: #121212;
  --loading-dots-color-light: #000000;
  --loading-dots-color-dark: #ffffff;
}

body.dark-mode {
  --primary-color: var(--primary-color-dark);
  --secondary-color: var(--secondary-color-dark);
  --bot-background-color: var(--bot-background-color-dark);
  --border-color: var(--border-color-dark);
  --text-color: var(--text-color-dark);
  --background-color: var(--background-color-dark);
  --loading-dots-color: var(--loading-dots-color-dark);
}
.menu-icon {
  color:rgba(75, 75, 75, 0.8);
  font-weight:bold ;
  position: relative;

}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.chat-container {
  display: flex;
  height: calc(100vh - 60px);
  padding: 0;
}

/* for mobile devices */
@media (max-width: 767px) {
  .chat-container {
    height: 80vh;
  }
}

.sidebar {
  position: relative;
  flex: 0 0 250px;
  max-width: 250px;
  transition: all 0.3s;
  background-color: rgba(54, 183, 183, 0.8);
  padding: 10px;
  border-right: 3px solid var(--border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow */
  border: 1px solid var(--border-color); /* Added border */
}

.sidebar.collapsed {
  flex: 0 0 50px;
  max-width: 50px;
  overflow: hidden;
  background-color: transparent;
}

.sidebar .sidebar-toggle-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  
  
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid var(--border-color);
  max-height: 82vh;
  margin-bottom: 10px;
}

.message-wrapper {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  animation: fadeInUp 0.5s ease-out;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: flex-start;
}

.icon {
  font-size: 1.5rem;
}

.message-box {
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.message.user {
  justify-content: flex-end;
}

.message.user .message-box {
  background-color: var(--secondary-color-light);
  align-self: flex-end;
  max-width: 70%;
}

body.dark-mode .message.user .message-box {
  background-color: var(--secondary-color-dark);
}

.message.bot .message-box {
  background-color: var(--bot-background-color-light);
  max-width: 95%;
}

body.dark-mode .message.bot .message-box {
  background-color: var(--bot-background-color-dark);
}

.message-text {
  display: block;
}

.message-time {
  display: block;
  font-size: 0.75rem;
  color: #999;
  margin-top: 5px;
  text-align: right;
}

.message-text.typing {
  overflow: hidden;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.custom-list {
  list-style-type: none;
  padding-left: 0;
}

.custom-list li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading-dots {
  display: inline-block;
  width: 80px;
  text-align: center;
}

.loading-dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: var(--loading-dots-color-light);
  border-radius: 50%;
  animation: loading-animation 1.4s infinite ease-in-out both;
}

body.dark-mode .loading-dots span {
  background-color: var(--loading-dots-color-dark);
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loading-animation {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
/* Download button container */
.download-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin-top: 15px; /* Reduce spacing above the button */
  margin-bottom: 10px; /* Reduce spacing below the button */
}

/* Download button */
.download-button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px; /* Reduce space between the icon and text */
  background-color: #053568; /* Primary button color */
  color: white; /* Text and icon color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 6px 15px; /* Reduce padding around the button */
  font-size: 14px; /* Smaller button text size */
  font-weight: 500; /* Slightly lighter font weight */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover effect for button */
.download-button-container button:hover {
  background-color: #050505; /* Darker shade of blue on hover */
}

/* Focus effect for button */
.download-button-container button:focus {
  outline: 2px solid #0056b3; /* Outline for accessibility */
}

/* Active button effect */
.download-button-container button:active {
  background-color: #004085; /* Even darker shade of blue when clicked */
  transform: scale(0.97); /* Slightly shrink the button on click */
}

/* Icon inside the button */
.download-button-container button svg {
  font-size: 16px; /* Smaller icon size */
}
