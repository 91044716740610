
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
}

@media (min-width: 800px) {
  body {
    overflow: hidden; 
  }
}

.main-content {
  padding-bottom: 30px; 
}

*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.login-main body{
  font-family: "Poppins", sans-serif;
  height: calc(100vh - 100px);
}

.login-main{
  display: flex;
}

.login-left{
  flex-grow: 1;
  background-color: #E9E9E9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-left img{
  width: 300px;
}

.login-right{
  height: calc(100vh - 60px);
  flex-grow: 1;
}


@media (max-width: 767px) {
  .login-main {
    flex-direction: column;
    align-items: center;
  }

  .login-right {
    width: 100%;
  }

  .login-left {
    display: none;
  }
}

.login-right-container{
  width: 80%;
  margin: 0 auto;
  --primary-color: var(--primary-color-light);

  display: flex;
  flex-direction: column;
  justify-content: center;

}

.login-right form{
  display: flex;
  flex-direction: column;
  top:25px;
  position: relative;
  
}

.login-logo{
  align-self: center;
  padding-top: 50px;
  background-color: transparent;
}
.login-logo {
  background-color: var(--login-logo-bg-light); 
  padding: 10px; 
  border-radius: 5px; 
}

.dark-mode .login-logo {
  background-color: var(--login-logo-bg-dark);
}

.light-mode .login-logo {
  background-color: var(--login-logo-bg-light);
}

.login-center {
  margin: auto 0;
  bottom: 17px ;/* Move it up by decreasing the value */
  position: relative;
}


.login-logo img{
  width: 100px;
}

.login-center h2{
  font-size: 2rem;
}

.login-center{
  text-align: center;
}

.login-center p{
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 40px;
}
.footer-separator {
  margin: 0 0px;     /* Minimal space between the separator and the links */
  font-weight: bold; /* Makes the separator bold */
  color: #000;       /* Adjust the color as needed */
}


.login-form input[type='email'],
.login-form input[type='password'],
.login-form input[type='text']
 {
  font-size: 1.1rem;
  width: 100%;
  padding: 13px;
  margin-bottom: 16px;
  border: 0px;
  
  border-bottom: 1px solid black;
  outline: none;
  box-sizing: border-box;
}

.pass-input-div{
  position: relative;
}

.pass-input-div svg{
  font-size: 20px;
  position: absolute;
  right: 10px;
  bottom: 35px;
  cursor: pointer;
  outline: none;
  color: grey;
}

.login-form button {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: 600;
}

.login-center-options{
  display: flex;
  justify-content: space-between;
  
}

.forgot-pass-link{
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  color:rgba(54, 183, 183, 0.8);
}
.sign-up{
  color:rgba(54, 183, 183, 0.8);
  font-weight: bold;
}
.forgot-pass-link:hover{
  text-decoration: underline;
  color:rgba(54, 183, 183, 0.8);
}

.login-center-buttons{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 20px;


}

.login-center-buttons button:nth-child(1){
  background-color:rgba(54, 183, 183, 0.8);
  color: white;
  border: 2px solid rgba(54, 183, 183, 0.8);
}

.login-center-buttons button:nth-child(1):hover{
  color: #333;
  background-color: white;
}

.login-center-buttons button:nth-child(2){
  background-color: #F0F0F0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.login-center-buttons button:nth-child(2) img{
  width: 30px;
}

.login-center-buttons button:nth-child(2):hover{
  background-color: #c4c4c457;
}

.login-bottom-p{
  text-align: center;
  font-size: 1rem;
  padding-bottom: 10px;
  margin-top: 2px;
  
  
}

.login-bottom-p a{
  text-decoration: none;
  font-weight: 600;
  
}

.login-bottom-p a:hover{
  text-decoration: underline;
}