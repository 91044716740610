.session-item {
    background-color: rgba(54, 183, 183, 0.8);
    color: white;
    cursor: pointer;
    padding: 0.5rem;
    padding-right: 0;
    word-wrap: break-word;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .file-item {
    background-color: #f8f9fa;
    color: #4e749c;
    cursor: pointer;
    padding: 0.5rem;
    word-wrap: break-word;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .latest-container {
    background-color: #f0ad4e; /* Example color, you can choose your preferred color */
    color: white;
  }

  .custom-file-input {
    cursor: pointer;
  }