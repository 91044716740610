.icon {
  font-size: 5rem; /* Adjusted font-size for icon */
  cursor: pointer;
  color: rgba(54, 183, 183, 0.8);
  position: relative;
  margin-left: 10px; 
  right: 5px;
  transition: color 0.3s ease; /* Smooth transition on hover */
}

.icon:hover {
  color: rgba(54, 183, 183, 1); /* Slightly brighter on hover */
}

.popup {
  position: absolute;
  top: 50px;
  border: 1px solid #ccc;
  padding: 1rem;
  background-color: white;
  border-radius: 10px; /* Smooth, modern corner rounding */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for a clean look */
  z-index: 9999;
  width: 18rem; /* Slightly larger for better spacing */
}

.username {
  font-weight: bold;
  font-size: 1rem; /* Slightly larger font for better emphasis */
  color: #333;
  margin-bottom: 0.5rem;
  text-align: left; /* Centered text for a polished look */
  word-wrap: break-word; /* Allows long words to break and wrap */
  white-space: normal; /* Ensures the text wraps to a new line if too long */
}

.payment-status {
  padding: 8px 10px; /* Padding for better spacing */
  border-radius: 6px; /* Smooth rounded corners */
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
}

.payment-status.premium {
  background-color: #54b689; /* Green for premium */
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
}

.payment-status.free {
  background-color: #ffcc00; /* Yellow for free plan */
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
}

.plan-expiry {
  padding: 15px;
  margin-top: 15px;
  background-color: #f9f9f9;
  border-left: 4px solid #54b689;
  border-radius: 6px;
  font-style: italic;
  color: #333;
}

.plan-expiry p {
  margin: 5px 0;
  font-weight: 600;
  font-size: 0.8rem;
}

.days-left {
  color: #2385e8;
  font-weight: bold;
  font-size: 0.9rem;
}
